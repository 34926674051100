<template>
  <div class="row">
    <div
      :class="{
        [$style.card__register]: true,
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="@/assets/images/logo.svg"
          width="250"
          height="auto"
        />
      </div>

      <div
        class="pbz-font subtitle-md-medium mt-5 mb-3 d-flex justify-content-center align-items-center"
        style="color: #1A1A1A"
      >
        {{ $t('auth.verifyAccount') }}
      </div>

      <div
        class="pbz-font body-md-regular my-3 d-flex justify-content-center align-items-center"
        style="color: #999999"
      >
        {{ $t('auth.sendOtpTo') }}
      </div>

      <div
        class="pbz-font body-md-medium mt-3 d-flex justify-content-center align-items-center"
        style="color: #1A1A1A"
      >
        {{ getOtpSendTo }}
      </div>

      <div class="my-3 d-flex justify-content-center align-items-center">
        <OTP :is-submited="isSubmited" @on-change="handleOnChangeOtp" @on-complete="handleOtpCompleted" />
      </div>

      <div v-if="errorMessage" class="d-flex justify-content-center align-items-center my-3">
        <div class="pbz-font body-md-medium" style="color: #EE4426">
          {{ errorMessage }}
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <div style="color: #1A1A1A !important" class="pbz-font body-md-medium">
          Kirim ulang kode dalam
          <span v-if="hours" style="color: #E00000 !important;">
            {{ hours }} : {{ minutes }} : {{ seconds }}
          </span>
          <span v-else style="color: #E00000 !important;">
            {{ minutes }} : {{ seconds }}
          </span>
        </div>
      </div>

      <div class="my-3 d-flex justify-content-center align-items-center">
        <div
          v-if="!hours && !minutes && !seconds"
          class="pbz-font body-sm-bold"
          :style="{
            color: '#E00000',
            cursor: 'pointer',
          }"
          @click.prevent="resendOtp"
        >
          {{ $t('auth.resendOtp') }}
        </div>
        <div
          v-else
          class="pbz-font body-sm-bold"
          :style="{ color: '#999999' }"
        >
          {{ $t('auth.resendOtp') }}
        </div>
      </div>

      <div
        :class="{
          [$style.anotherRegisterCard]: true,
        }"
        class="mt-5 mb-2 d-flex justify-content-center align-items-center"
        :style="{
          backgroundColor: otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) ? '#E00000 !important' : '#999999 !important' ,
          border: otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) ? 'ipx solid #E00000 !important' : '1px solid #999999 !important' ,
          cursor: otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) && 'pointer'
        }"
        @click.prevent="() => otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) && $emit('validateOtp')"
      >
        <div v-if="!loading" class="pbz-font button-md-medium" style="color: #FFFFFF !important">
          Validasi
        </div>
        <div v-if="loading">
          <a-icon type="loading" /> Loading
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OTP from '@/components/OTP/index.vue'
import Cookies from 'js-cookie'

export default {
  components: {
    OTP,
  },
  props: {
    errorMessage: {
      type: String,
      default: () => null,
    },
    otp: {
      type: String,
      default: () => '',
    },
    isStartCountdown: {
      type: Boolean,
      default: () => false,
    },
    timestampExpired: {
      type: Number,
      default: () => 0,
    },
    targetSentOtp: {
      type: String,
      default: () => ('' || null),
    },
  },
  data () {
    return {
      isSubmited: false,
      minutes: 0,
      seconds: 0,
      hours: 0,
      otpExpired: 0,
      actualTime: this.$moment().format('X'),
    }
  },
  computed: {
    loading () {
      return this.$store.state.user.loading
    },
    getOtpSendTo () {
      // sebelum masuk ke page ini.
      // di page sebelumnya tolong buat cookie dengan key = otpSendTo, valuenya antara email, phone, dll
      // disini di getnya untuk informasi otp tersebut dikirim kemana
      const userData = Cookies.get('dataUserInvited') ? JSON.parse(Cookies.get('dataUserInvited')) : {}
      if (this.targetSentOtp) {
        return this.targetSentOtp
      }

      if (userData.invited_via === 'email') {
        return userData.email
      }

      if (userData.invited_via === 'phone') {
        return userData.phone_number
      }

      return null
    },
  },
  watch: {
    actualTime () {
      if (!this.seconds && !this.minutes) {
        return null
      } else {
        this.compute()
      }
    },
    // isStartCountdown: {
    //   handler(newVal, oldVal) {
    //     this.otpExpired = this.timestampExpired
    //     this.startCountdown()
    //   },
    // },
  },
  created () {
    if (this.isStartCountdown && this.timestampExpired) {
      this.otpExpired = this.timestampExpired
      this.startCountdown()
    }
  },
  methods: {
    handleOnChangeOtp (otp) {
      this.$emit('setOtp', otp)
    },
    handleOtpCompleted (otp) {
      this.$emit('setOtp', otp)
    },
    startCountdown () {
      this.compute()
      this.addOneSecondToActualTime()
      // this.isStartCountdown = false
    },
    compute () {
      const duration = this.$moment.duration(this.otpExpired, 'seconds')
      this.hours = duration.hours() > 0 ? duration.hours() : 0
      this.minutes = duration.minutes() > 0 ? duration.minutes() : 0
      this.seconds = duration.seconds() > 0 ? duration.seconds() : 0
    },
    addOneSecondToActualTime () {
      this.actualTime = this.$moment().format('X')
      setTimeout(() => {
        if (!this.minutes && !this.seconds) {
          clearInterval()
        } else {
          this.otpExpired -= 1
          this.addOneSecondToActualTime()
        }
      }, 1000)
    },
    resendOtp() {
      if (!this.hours && !this.minutes && !this.seconds) {
        this.$emit('resendOtp')
        this.otpExpired = this.timestampExpired
        this.startCountdown()
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
